.SnackbarControlAlert .MuiAlert-icon {
  align-items: center;
}

.SnackbarControlAlert .MuiAlert-message {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  align-items: center;
  justify-content: center;
}
