body {
  margin: 0px;
  background: #f4f4f4;
  overflow: hidden;
}

/*h1, h2, h3, h4 {
  color: #0D2E4C;
}*/

main {
  display: flex;
  position: relative;
  height: 100vh;
}

.full {
  width: 100%;
  height: 100%;
}

.main-content {
  flex-grow: 1;
  margin-top: 64px;
  height: calc(100% - 64px);
  min-height: calc(100vh - 64px);
  transition: left 0.15s linear;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  background: white;
}

.main-content-padded {
  padding: 24px;
}

.main-content-full {
  width: 100%;
  left: 0;
}

.main-content.hideNav,
.main-content.main-content-full.hideNav {
  top: 0;
  height: 100%;
  left: 0;
  width: 100%;
  margin: 0;
}

.account,
.contact {
  display: block;
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.account.hidden,
#root .contact.hidden {
  display: none;
}

.account iframe {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: none;
}

div.MuiTooltip-tooltip {
  color: white;
}

.max-width {
  width: 100%;
}

button.material-table-add-button {
  position: absolute;
  top: 16px;
  left: 12px;
  width: 30px;
  height: 30px;
  min-width: 0px;
  border-radius: 180px;
  padding: 6px;
}

.material-table-toolbar {
  margin-left: 50px;
  overflow: hidden;
}

div.orders-control-content {
  padding-left: 18px;
  padding-right: 18px;
  height: 100%;
}

div.orders-control-card-content {
  height: calc(100% - 64px);
  overflow-y: auto;
}

div.orders-control-scroll {
  height: 100%;
  overflow-y: auto;
}

.orders-margin-top {
  margin-top: 18px;
}

.orders-margin-bottom {
  margin-bottom: 18px;
}

.orders-right-align {
  text-align: right;
}

.orders-action-button {
  margin: 8px 0px;
}

.orders-error {
  color: red;
  font-size: 14px;
}

/*div.MuiCardContent-root {
  padding-top: 0px;
}*/

.clickable:hover {
  text-decoration: underline;
  cursor: pointer;
}

.overflow-hidden {
  overflow: hidden;
}

.suspenseContainer {
  height: 100%;
}

.mapLoadingModal {
  display: flex;
  justify-content: center;
  align-items: center;
}

a.routerLink {
  color: inherit;
  text-decoration: none;
  display: block;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .MuiInputBase-input.MuiInput-input {
    padding: 0px 0px 0px 0px;
  }
}
/* default mui slider background color to be the primary app color */
.MuiSlider-valueLabel {
  background: #1976d2;
}
